<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('pos_shifts.pos_shifts') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('pos_shifts.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{ $t('pos_shifts.parent_category') }}</label>-->
<!--                <treeselect-->
<!--                    :class="validation && validation.parent_category ? 'is-invalid' : ''"-->
<!--                    :options="categories"-->
<!--                    :load-options="loadOptions"-->
<!--                    :multiple="false"-->
<!--                    :value="data.parent_category"-->
<!--                    @input="updateValue"-->
<!--                    :searchable="true"-->
<!--                    :show-count="true"-->
<!--                    :no-children-text="$t('No_sub_options')"-->
<!--                    :no-options-text="$t('No_options_available')"-->
<!--                    :no-results-text="$t('No_results_found')"-->
<!--                    :placeholder="$t('Select')">-->
<!--                </treeselect>-->
<!--                <span v-if="validation && validation.parent_category" class="fv-plugins-message-container invalid-feedback">-->
<!--                                        {{ validation.parent_category[0] }}-->
<!--                                </span>-->
<!--              </div>-->
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch>{{ $t('is_default') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_shifts.image') }}</label>
                  <upload-file
                      @file="listenerAttachment"
                      :inner-id="'image'"
                      :placeholder="$t('pos_shifts.upload_image')"
                      :upload="dir_upload"
                      :start-link="'base'"
                      v-bind:valueprop="{name:'attachment',path:img_url}" v-if="reloadUploadAttachment">
                  </upload-file>
                  <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.attachment[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_shifts.description') }}</label>
                  <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-pos-shifts",
  data() {
    return {
      mainRoute: 'pos/pos-shifts',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'pos_shifts',
      categories: [],


      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        parent_category: null,
        img: null,
        description: null,
        status: true,
        is_default: false,
      },
      isEditing: false,
      validation: null,

      img_url: null,
      reloadUploadAttachment: true,
    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-shifts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-shifts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.parent_category = response.data.data.parent_category;
        this.data.img = response.data.data.img;
        this.img_url = response.data.data.img_url;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
        this.data.is_default = response.data.data.is_default;
        this.reloadUploadAttachment = true;
      });
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    loadOptions() {
    },
    updateValue(value) {
      this.data.parent_category = value
    },
    listenerAttachment(event) {
      if (event) {
        this.img_url = event.pathDB;
        this.data.img = event.name;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_shifts"), route: '/pos/pos-shifts'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getCategories();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


